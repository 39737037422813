<template>
  <!-- 优惠券列表弹窗组件 -->
  <el-dialog :visible="showGoods" @close="close">
    <div class="pad-container">
      <!-- 筛选条件 -->
      <div class="ali-c js-btw" v-if="searchArr.length">
        <template v-for="(item, index) in searchArr">
          <el-cascader
            v-if="item.name == 'goodsClassifyId'"
            class="form-input-150 mr-10"
            :placeholder="item.placeholder"
            v-model="item.value"
            :options="classificationList"
            :props="{ expandTrigger: 'hover' }"
            :key="index"
          >
          </el-cascader>

          <el-select
            v-else-if="item.name == 'storeId'"
            :key="index"
            :placeholder="item.placeholder"
            v-model="item.value"
            @change="getMerchantsListHandle(item.value)"
            class="form-input-150 mr-10"
          >
            <el-option
              v-for="(item, ind) in store_list"
              :key="ind"
              :value="item.id"
              :label="item.storeName"
            ></el-option>
          </el-select>

          <el-select
            v-else-if="item.name == 'merchantId'"
            :key="index"
            v-model="item.value"
            filterable
            :placeholder="item.placeholder"
            class="form-input-150 mr-10"
          >
            <el-option
              v-for="item in merchantList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            >
            </el-option>
          </el-select>
          <el-select
            v-else-if="item.name == 'status'"
            :key="index"
            :placeholder="item.placeholder"
            v-model="item.value"
            class="form-input-150 mr-10"
          >
            <el-option
              v-for="(item, index) in coupStatusList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-else-if="item.name == 'couponClass'"
            :key="index"
            :placeholder="item.placeholder"
            v-model="item.value"
            class="form-input-150 mr-10"
          >
            <el-option
              v-for="(item, index) in couponClassList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>

          <el-input
            v-else
            :placeholder="item.placeholder"
            class="form-input-150 mr-10"
            v-model.trim="item.value"
            :key="index"
          ></el-input>
        </template>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          class="ml-15"
          @click="search"
          >查询</el-button
        >
        <el-button
          size="small"
          icon="el-icon-refresh"
          class="ml-15"
          @click="refresh"
          >刷新</el-button
        >
      </div>
      <el-tabs
        v-model="activeName"
        @tab-click="handleTab"
        v-if="hasTab"
        class="mt-10"
      >
        <el-tab-pane
          :label="item.label"
          :name="item.name"
          v-for="(item, index) in tabList"
          :key="index"
        >
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            :ref="'multipleTable' + index ? index : ''"
            :row-key="
              (row) => {
                return row[key];
              }
            "
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              v-if="selected"
              :key="index"
            ></el-table-column>
            <el-table-column
              :label="item.value"
              v-for="item in tableHeader"
              :key="item.key"
            >
              <template slot-scope="scope">
                <el-avatar
                  v-if="item.key == 'imgUrl'"
                  :src="picsPath(scope.row.imgUrl)"
                  shape="square"
                  alt="加载失败"
                  :key="scope.row.imgUrl"
                ></el-avatar>
                <template v-else-if="item.key == 'operations'">
                  <div class="flx-row ali-c">
                    <span class="ml-10 tip-text-info" @click="select(scope.row)"
                      >选择</span
                    >
                  </div>
                </template>
                <template v-else-if="item.key == 'couponType'">
                  {{
                    scope.row.type == 0
                      ? "折扣券"
                      : scope.row.type == 1
                      ? "代金券"
                      : scope.row.type == 2
                      ? "兑换券"
                      : scope.row.type == 3
                      ? "停车券"
                      : ""
                  }}
                </template>
                <template v-else-if="item.key == 'coupon'">
                  {{ couponType[scope.row.coupon] }}
                </template>
                <template v-else-if="item.key == 'drawCondition'">
                  {{ scope.row.drawCondition == 1 ? "免费领取" : "限领" }}
                </template>
                <template v-else>{{ scope.row[item.key] }}</template>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <!--     ------------------------------- -->
      <el-table
        v-else
        class="mt-24 member-table"
        :data="tableData"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        :row-key="rowKey"
      >
        <el-table-column
          type="selection"
          v-if="selected"
          :reserve-selection="true"
          :selectable="isCanSelect"
        ></el-table-column>
        <el-table-column
          :label="item.value"
          v-for="item in tableHeader"
          :key="item.key"
        >
          <template slot-scope="scope">
            <el-avatar
              v-if="item.key == 'imgUrl'"
              :src="picsPath(scope.row.imgUrl)"
              shape="square"
              alt="加载失败"
              :key="scope.row.imgUrl"
            ></el-avatar>
            <template v-else-if="item.key == 'coupon'">
              {{
                scope.row.coupon == 1
                  ? "通用券"
                  : scope.row.type == 2
                  ? "商品券"
                  : scope.row.type == 3
                  ? "餐饮券"
                  : scope.row.type == 4
                  ? "折扣券"
                  : scope.row.type == 5
                  ? "代金券"
                  : scope.row.type == 6
                  ? "停车券"
                  : scope.row.applicableObjectsName
              }}
            </template>
            <template v-else-if="item.key == 'drawCondition'">
              {{
                scope.row.drawCondition == 1
                  ? "免费领取"
                  : scope.row.drawCondition == 2
                  ? "限领"
                  : scope.row.disbursement
              }}
            </template>
            <template v-else-if="item.key == 'operations'">
              <div class="flx-row ali-c">
                <span class="ml-10 tip-text-info" @click="select(scope.row)"
                  >选择</span
                >
              </div>
            </template>
            <template v-else>{{ scope.row[item.key] }}</template>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :pageNum="pageNum"
        :pageSize="pageSize"
        :hideSizes="true"
        :type="'dialog'"
        @syncPageData="syncPageData"
      />
    </div>
    <div class="flx-row ali-c js-fe mt-50" v-if="selected">
      <el-button plain size="medium" @click="cancelSelect">取消</el-button>
      <el-button class="ml-15" size="medium" type="primary" @click="chooseGoods"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  getProcudtList, //商品列表
  getStoreList, //门店列表
  getMerchantsList, //商户
} from "@/api/online_department_store/commodity_manage";
import { getProcudtCateListAll } from "@/api/online_department_store/categories_manage";
import { getCouponList } from "@/api/maketing/coupons"; //优惠券列表
export default {
  props: {
    storeId: String,
    tabList: Array,
    hiddenSelectedStore: false,
    hasTab: {
      type: Boolean,
      default: false,
    },
    tableHeader: {
      type: Array,
      default: [
        {
          value: "商品条形码",
          key: "barCode",
        },
        {
          value: "商品名称",
          key: "goodsName",
        },
        {
          value: "一级分类",
          key: "firstClassifyName",
        },
        {
          value: "二级分类",
          key: "secondClassifyName",
        },
        {
          value: "实价",
          key: "price",
        },
        {
          value: "库存",
          key: "stock",
        },
      ],
    },
    searchArr: {
      type: Array,
      default: () => [
        {
          name: "goodsName",
          value: "",
          placeholder: "请输入商品名称",
        },
        {
          name: "goodsClassifyId",
          value: "",
          placeholder: "请选择商品分类",
        },
        {
          name: "storeId",
          value: "",
          placeholder: "请选择所属门店",
        },
        {
          name: "merchantId",
          value: "",
          placeholder: "请选择商户",
        },
        {
          name: "couponClass",
          value: "",
          placeholder: "请选择优惠券类型",
        },
      ],
    },
    keyId: {
      type: [String, Number],
      default: "goodsId",
    },
    selected: {
      type: Boolean,
      default: true,
    },
    selectArr: {
      //美美加的  从父组件传来的选中数组
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    Pagination,
  },
  data() {
    return {
      activeName: "1",
      showGoods: false,
      pageNum: 1,
      pageSize: 5,
      firstFlag: true,

      act_form: {
        list: [],
      },
      tableData: [],
      total: 0,
      tableName: null,
      key: this.keyId,
      index: 0,
      type: "1",
      id: "",
      classificationList: [],
      store_list: [],
      merchantList: [],
      coupStatusList: [
        { id: 1, name: "上架" },
        { id: 0, name: "下架" },
      ],
      //优惠券类型 1.线上 2 线下
      couponClassList: [
        { id: 1, name: "线上" },
        { id: 2, name: "线下" },
      ],
      couponType: [
        "",
        "通用劵",
        "商品劵",
        "餐饮优惠券",
        "折扣券",
        "代金券",
        "停车券",
      ],
      selected_temp: [],
      multipleSelection: [], //美美加的   多选框选中的数组
    };
  },
  mounted() {
    this.getCateList();
    this.getStores();
    this.getMerchantsListHandle("");
    // console.log(this.tableHeader)
    //console.log(this.keyId);
  },
  methods: {
    // 切换tab
    handleTab(val) {
      this.pageNum = 1;
      this.tableName = this.$refs[val.index][0];
      let data = this.tabList[val.index];
     // console.log(data);
      this.type = data.type;
      this.key = data.key;
      this.getGoods(true);
    },
    // 取消勾选
    cancelSelect() {
      this.showGoods = false;
      this.multipleSelection = [];
      try {
        //  this.$refs.multipleTable.clearSelection();
      } catch (err) {}
    },
    //表前  复选框
    isCanSelect(row) {
      if (this.keyId == "couponId") {
        return row.remainingNumber > 0;
      }else{
        return row.stock > 0;
      }
      
    },

    // 关闭弹窗
    close() {
      this.showGoods = false;
    },
    // 换页
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.getGoods(this.selected ? true : "");
    },
    // 获取商品分类
    getCateList() {
      let data = {};
      getProcudtCateListAll({ filter: 0 }).then((res) => {
        if (res.data.code == 200) {
          this.classificationList = res.data.body;
        }
      });
    },
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.store_list = res.data.body.list;
      });
    },
    //获取商户列表
    getMerchantsListHandle(storeId) {
      getMerchantsList({ shopId: storeId }).then((res) => {
        this.merchantList = res.data.body.list;
      });
    },
    //获取优惠券  商品 列表
    getGoods(isSelect) {
      // this.multipleSelection = this.selectArr;
      console.log(this.multipleSelection)
      // console.log(this.selectArr,"selectArr")
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      let searchObj = {};
      let searchArr = JSON.parse(JSON.stringify(this.searchArr));
      // console.log(this.searchArr);
      if (searchArr.length) {
        //赋值筛选条件
        searchArr.map((item) => {
          if (item.name == "goodsClassifyId") {
            searchObj[item.name] = item.value
              ? item.value[item.value.length - 1]
              : "";
          } else if (item.name == "couponClass") {
            item.name = "couponType";
            searchObj[item.name] = item.value;
          } else {
            searchObj[item.name] = item.value;
          }
        });
      }
      if (this.hiddenSelectedStore) {
        //从创建优惠券而来  隐藏选择门店
        searchObj.storeId = this.storeId;
      }
      // console.log(searchObj);
      data = Object.assign(data, searchObj);
     console.log(data);
    //  console.log(this.keyId)
      //  this.$emit("getTableList", data);
      if (this.keyId == "couponId") {  //优惠券  
        getCouponList({ ...data }).then((res) => {
          console.log(res.data.body)
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
          //进行状态勾选
          // console.log(res.data.body.list)
          res.data.body.list.forEach((ele) => {
            this.$nextTick(() => {
              this.$refs["multipleTable"].toggleRowSelection(ele, false);
            });
          });
       //   console.log(this.multipleSelection)
       //   console.log(this.selectArr)
          res.data.body.list.forEach((ele) => {
            this.selectArr.forEach((element) => {
              if (ele.goodsId == element.goodsId) {
                // console.log(ele.goodsId , element.goodsId)
                this.$nextTick(() => {
                  this.$refs["multipleTable"].toggleRowSelection(ele, true);
                });
              } else {
                //  console.log(ele.goodsId , element.goodsId)
                // this.$nextTick(() => {
                //   this.$refs["multipleTable"].toggleRowSelection(ele, false);
                // });
              }
            });
          });
        });
      } else {
        //商品
        //我改了  查询商品列表
        getProcudtList({ goodsStatus: 1, ...data }).then((res) => {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
          //进行状态勾选
          // data.toggleSelection()
          //this.defaultSelection(); //默认勾选
          // console.log(res.data.body.list)
          res.data.body.list.forEach((ele) => {
            this.$nextTick(() => {
              this.$refs["multipleTable"].toggleRowSelection(ele, false);
            });
          });
         //  console.log(this.multipleSelection)
         // console.log(this.selectArr)
          let tempArr = [...new Set(this.selectArr.concat(this.multipleSelection))] 
        //  console.log("tempArr",tempArr)
          res.data.body.list.forEach((ele) => {
            tempArr.forEach((element) => {
              if (ele.goodsId == element.goodsId) {
                // console.log(ele.goodsId , element.goodsId)
                this.$nextTick(() => {
                  this.$refs["multipleTable"].toggleRowSelection(ele, true);
                });
              } else {
                //  console.log(ele.goodsId , element.goodsId)
                // this.$nextTick(() => {
                //   this.$refs["multipleTable"].toggleRowSelection(ele, false);
                // });
              }
            });
          });
        });
      }
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.pageSize = 5;
      this.getGoods(this.selected ? true : "");
    },
    // 刷新
    refresh() {
      this.searchArr.forEach((item) => {
        item.value = "";
      });
      this.search();
    },

    //确定  多选商品  多选框
    chooseGoods() {
      //  this.act_form.list = JSON.parse(JSON.stringify(this.selected_temp));
      // console.log(this.multipleSelection);
    //  console.log([...new Set(this.multipleSelection)]);
      this.$emit("getSelectedList", [...new Set(this.multipleSelection)]);
      this.showGoods = false;
      this.multipleSelection= [];

    },
    //单选
    select(row) {
      this.act_form.list = JSON.parse(JSON.stringify([row]));
      this.showGoods = false;
      this.$emit("getSelectedList", this.act_form.list[0], this.index);
    },
    rowKey(row) {
      return row.goodsId;
    },
    //多选框值改变的时候
    handleSelectionChange(val) {
    //  console.log("val",val)
      this.multipleSelection = val;
    },

    // 切换勾选状态 (列表已经选中的 在弹窗中同样选中)
    // toggleSelection() {
    //   this.$nextTick(() => {
    //     let goodsIdList = this.act_form.list.map((item) => item[this.key]);
    //     let selected_temp = this.selected_temp.map((item) => item[this.key]);
    //     // 本页数据的勾选
    //     this.tableData.forEach((item) => {
    //       // 不在已选商品中则去除勾选
    //       if (goodsIdList.indexOf(item[this.key]) === -1) {
    //         if (this.hasTab) {
    //           this.tableName.toggleRowSelection(item, false);
    //         } else {
    //           this.$refs.multipleTable.toggleRowSelection(item, false);
    //         }
    //       }
    //       // 在全局勾选列表中添加勾选
    //       if (selected_temp.indexOf(item[this.key]) !== -1) {
    //         if (this.hasTab) {
    //           this.tableName.toggleRowSelection(item, true);
    //         } else {
    //           this.$refs.multipleTable.toggleRowSelection(item, true);
    //         }
    //       }
    //     });
    //   });
    // },
    // 手动全选
    // handleSelectAll(selection) {
    //   let selections = selection.map((item) => item[this.key]); // 用户手动选中的所有项id列表集合
    //   let selected_list = this.selected_temp.map((item) => item[this.key]); // 所有选中项id列表集合
    //   // 当前页里有被勾选的 只执行勾选操作
    //   let flag = this.tableData.some(
    //     (item) => selections.indexOf(item[this.key]) !== -1
    //   );
    //   for (var i = 0; i < this.tableData.length; i++) {
    //     let item = this.tableData[i];
    //     if (selected_list.indexOf(item[this.key]) !== -1 && !flag) {
    //       // 本页数据在全局勾选列表中 并且 本页数据不在用户手选列表中（表明执行了取消勾选操作）
    //       // 将本页数据从全局勾选列表中删除
    //       let index = this.selected_temp.findIndex(
    //         (value) => value[this.key] === item[this.key]
    //       );
    //       this.selected_temp.splice(index, 1);
    //     } else {
    //       // 本页数据项不在全局勾选列表中（执行全选操作） - 将数据项添加进全局勾选列表
    //       if (selected_list.indexOf(item[this.key]) === -1) {
    //         this.selected_temp.push(item);
    //       }
    //     }
    //   }
    // },
    // 手动单选
    // handleSelect(selection, row) {
    //   // 判断总勾选列表中是否含有点击项
    //   let includeFlag = this.selected_temp.some((item, index) => {
    //     return item[this.key] == row[this.key];
    //   });
    //   if (includeFlag) {
    //     // 有-删除
    //     let index = this.selected_temp.findIndex(
    //       (item) => item[this.key] === row[this.key]
    //     );
    //     this.selected_temp.splice(index, 1);
    //   } else {
    //     // 无-添加
    //     this.selected_temp.push(row);
    //   }
    // },
    /****     多选功能模块 end       ****/
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
</style>
